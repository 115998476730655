import api from "@/api/backend-api";
import {isNull} from "lodash";

const state = {
    backorders: [],
    searchTerm: null,
    loading: false,
    active_date: new Date(),
    filterJunk: false,
    approvedOnly: false,
    sibsMin: 0,
    devSibs: 0,
    devRels: 0,
    ageMin: 1,
    // googleMin: 10,
    lengthMax: 20,
    wordsCount: 4,
    siblingsToOwnersMin: 0,
    hasGTLD: false,
    hasGmail: false,
    hasSiblingPhone: false,
    hasCloudFlare: false,
    hasUSWebsiteWithEmailAndPhone: false,
    fatSibling: false,
};

const mutations = {
    SET_SEARCH_TERM(state, payload) {
        state.searchTerm = payload;
    },
    SET_JUNK_FILTER(state, payload) {
        state.filterJunk = payload;
    },
    SET_APPROVED_ONLY(state, payload) {
        state.approvedOnly = payload;
    },
    SET_HAS_GTLD(state, payload) {
        state.hasGTLD = payload;
    },
    SET_SIBS_MIN(state, payload) {
        state.sibsMin = +payload;
    },
    SET_DEV_SIBS(state, payload) {
        state.devSibs = +payload;
    },
    SET_DEV_RELS(state, payload) {
        state.devRels = +payload;
    },
    SET_AGE_MIN(state, payload) {
        state.ageMin = +payload;
    },
    SET_GOOGLE_MIN(state, payload) {
        state.googleMin = payload;
    },
    SET_LENGTH_MAX(state, payload) {
        state.lengthMax = payload;
    },
    SET_WORDS_COUNT(state, payload) {
        state.wordsCount = payload;
    },
    SET_S2O_MIN(state, payload) {
        state.siblingsToOwnersMin = payload;
    },
    SET_BACKORDERS(state, data) {
        state.backorders = data;
    },
    SET_ONLY_RELATIVES(state, data) {
        state.onlyRels = data;
    },
    SET_HAS_GMAIL(state, data) {
        state.hasGmail = data;
    },
    SET_HAS_SIBLING_PHONE(state, data) {
        state.hasSiblingPhone = data;
    },
    SET_HAS_CLOUDFLARE(state, data) {
        state.hasCloudFlare = data;
    },
    SET_HAS_US_WEB_WITH_PHONE_AND_EMAIL(state, data) {
        state.hasUSWebsiteWithEmailAndPhone = data;
    },
    SET_FAT_SIBLING(state, data) {
        state.fatSibling = data;
    },
    SET_ACTIVE_DATE(state, payload) {
        state.active_date = payload;
    },
    DELETE_BACKORDER(state, payload) {
        state.backorders = state.backorders.filter(b => b.id !== payload.id)
    },
    UPDATE_BACKORDER(state, payload) {
        const updateIndex = state.backorders.findIndex(item => item.id === payload.id)

        state.backorders = [
            ...state.backorders.slice(0, updateIndex),
            payload,
            ...state.backorders.slice(updateIndex + 1)
        ]
    },
    SET_LOADING(state, payload) {
        state.loading = payload;
    },
};

const actions = {


    async search({commit}, payload) {
        commit("SET_SEARCH_TERM", payload.searchTerm);
        commit("SET_LOADING", true);
        let response = await api.searchBackorders(payload);
        commit("SET_BACKORDERS", response.data);
        commit("SET_ACTIVE_DATE", payload.date)
        commit("SET_LOADING", false);
    },

    async backorderByDate({state, commit}) {
        commit("SET_LOADING", true);

        let date = state.active_date.toISOString().slice(0, 10);
        console.log("Backorder by date ", date);
        let response = await api.backorderByDate(date);
        console.log(
            "Backorder response ",
            response
        );
        commit("SET_BACKORDERS", response.data);
        commit("SET_LOADING", false);

    },
    async produceBackorders({dispatch, state, commit}) {
        commit("SET_LOADING", true);
        let date = state.active_date.toISOString().slice(0, 10);

        console.log("Producing backorders for ", date);
        await api.produceBackorders(date);
        commit("SET_LOADING", false);

    },

    async deleteBackorder({dispatch, state, commit}, payload) {
        commit("SET_LOADING", true);

        console.log("Deleting backorder");
        let response = await api.deleteBackorder(payload.id);
        if (response.status && response.status == 200) {
            commit("DELETE_BACKORDER", payload);
            commit("SET_LOADING", false);
        }

    },

    async updateBackorder({dispatch, state, commit}, payload) {
        commit("SET_LOADING", true);

        console.log("Updating backorder", payload);
        let response = await api.updateBackorder(payload);
        commit("UPDATE_BACKORDER", response.data);

        commit("SET_LOADING", false);
    },


    async backorderAction({dispatch}, payload) {

        let response = await api.backorder(payload);
        console.log(
            "Backorder response ",
            response
        );
        let message;
        if (response.data.errorCode) {
            message = {
                data: response.data,
                color: "red",
                timeout: 2000,
            };
        } else {
            message = {
                data: response.data,
                color: "green",
                timeout: 2000,
            };
            // updateConversationStatus();
            let conversationsUpdate = {
                domainName: payload.domainName,
                state: "ordered",
            }
            dispatch("conversations/updateByNameAction", conversationsUpdate, {root: true});
            dispatch("messages/updateMessageStageAction", conversationsUpdate, {root: true});

        }

        dispatch("snackbar/setSnackbar", message, {root: true});
    },
};

const getters = {
    /*  getDomainById: (state) => (id) => {
        return state.domains.find((domain) => domain.id === id);
      },*/
    getDomainByName: (state) => (domName) => {
        let domain = state.domains.find((domain) => domain.name.toLowerCase() === domName.toLowerCase());
        return domain;
    },
    getDomainByNameFromAPI: () => (domName) => {
        return api.getDomainByName(domName);
    },
    getDomains: (state) => {
        return state.domains;
    },

    getGNAMEBulkText: (state) => {
        let text = '';
        state.backorders.filter(b => b.approved).forEach(b => text += `${b.domainName.toLowerCase()}\n`)
        return text;
    },
    getSnapBackordersText: (state) => {
        let text = '';
        state.backorders.filter(b => b.approved && b.bid === 59).forEach(b => text += `${b.domainName.toLowerCase()}:79\n`)
        return text;
    },
    getDCBackordersText: (state) => {
        let text = '';
        state.backorders.filter(b => b.approved).forEach(b => text += `${b.domainName.toLowerCase()},${b.bid}\n`)
        return text;
    },
    getSortedBackorders() {
        if (state.searchTerm) return state.backorders;

        let sorted = state.approvedOnly ? state.backorders.filter(b => b.approved) : state.backorders;
        sorted = state.filterJunk ? sorted.filter(b => b.approved !== false) : sorted;

        return sorted.filter(b =>
            b.siblingsCount >= state.sibsMin
            && b.developedSiblingsCount >= state.devSibs
            && b.developedRelativesCount >= state.devRels
            && b.age >= state.ageMin
            && b.siblingsToOwners >= state.siblingsToOwnersMin
            && b.nameLength <= state.lengthMax
            && (!b.wordCount || b.wordCount <= state.wordsCount)
            && (state.hasGTLD ? b.hasGTLDSibling : true)
            && (state.hasGmail ? b.hasGMailLead : true)
            && (state.hasSiblingPhone ? b.hasSiblingPhone : true)
            && (state.hasCloudFlare ? b.hasCloudFlareLead : true)
            && (state.hasUSWebsiteWithEmailAndPhone ? b.hasLeadWithEmailAndPhoneOnWebsite : true)
            && (state.fatSibling ? b.fatSibling : true)
        )
    },
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced,
};
