import backendAPI from "@/api/backend-api";

const state = {
    domains: [],
    danMessages: [],
    loading: false,
    danSync: false,
    cnmSync: false,

};

const mutations = {
    ADD_ALL_MESSAGES(state, data) {
        state.danMessages = data.map(
            (obj) => state.danMessages.find((o) => o.id === obj.id) || obj
        );
    },
    ADD_ALL(state, data) {
        state.domains = data
    },

    ADD_DOMAIN(state, data) {
        let updateIndex = state.domains.findIndex(
            (item) => item.id === data.id
        );
        if (updateIndex == -1) {
            state.domains.push(data);
        }
    },

    UPDATE_DOMAIN(state, domain) {
        if (state.domains) {
            console.log("Updating domain: ", domain);
            const updateIndex = state.domains.findIndex(
                (item) => item.id === domain.id
            );
            console.log("Found Index: ", updateIndex);
            state.domains = [
                ...state.domains.slice(0, updateIndex),
                domain,
                ...state.domains.slice(updateIndex + 1),
            ];
        }
    },

    UPDATE_DAN_MESSAGE(state, data) {
        const updateIndex = state.danMessages.findIndex(
            (item) => item.id === data.id
        );
        state.danMessages[updateIndex] = data;
        // state.domains = [
        //   ...state.domains.slice(0, updateIndex),
        //   domain,
        //   ...state.domains.slice(updateIndex + 1),
        // ];
    },

    UPDATE_DOMAINS(state, data) {
        state.domains = data.domains;
        state.totalPages = data.totalPages;
        state.totalElements = data.totalElements;
        state.currentPage = data.currentPage;
    },

    SET_LOADING(state, payload) {
        state.loading = payload;
    },

    SET_DAN_SYNC(state, payload) {
        state.danSync = payload;
    },
    SET_CNM_SYNC(state, payload) {
        state.cnmSync = payload;
    },
    SET_ACTIVE_DATE(state, payload) {
        state.active_date = payload;
    },
};

const actions = {
    async updateInventoryDomain({commit}, payload) {
        const response = await backendAPI.updateInventoryDomain(payload);
        commit("UPDATE_DOMAIN", response.data);
    },

    async sendWithDanAction({commit, dispatch}, payload) {
        const response = await backendAPI.sendMessageWithDan(payload);
        const {data} = response;
        console.log("Received response: ", response);
        const snack = {
            data,
            color: response.status === 200 ? "green" : "red",
            timeout: 2000,
        };
        dispatch("snackbar/setSnackbar", snack, {root: true});
    },

    async updateDanMessageAction({commit, dispatch}, payload) {
        const response = await backendAPI.updateDanMessage(payload);
        const data = response.data;
        console.log("Received response: ", data);
        const snack = {
            text: "Updated DAN Message: " + JSON.stringify(data.data),
            color: response.status === 200 ? "green" : "red",
            timeout: 2000,
        };
        dispatch("snackbar/setSnackbar", snack, {root: true});
        commit("UPDATE_DAN_MESSAGE", data);
    },

    async loadAllDays({commit}) {
        const response = await backendAPI.loadAllDays();
        let responses = await response.data;
        commit("ADD_ALL", responses);
    },

    async search({commit}, filter) {
        commit("SET_LOADING", true);
        let data = await backendAPI.searchInventoryDomains(filter);

        commit("ADD_ALL", data);

        commit("SET_LOADING", false);
    },

    async importInventoryAction({commit, dispatch}, payload) {
        commit("SET_LOADING", true);
        const {data, status} = await backendAPI.importInventory(payload);
        console.log("Data ", data, "Status", status);
        data.forEach((domain) => {
            const snack = {
                text: "Listing With Dan: " + domain.name,
                color: status === 200 ? "green" : "red",
                timeout: 2000,
            };
            dispatch("snackbar/setSnackbar", snack, {root: true});

        })


        commit("SET_LOADING", false);
    },

    async importInventoryDomainAction({commit, dispatch}, payload) {
        commit("SET_LOADING", true);
        const {data, status} = await backendAPI.importInventoryDomain(payload);
        const snack = {
            data: data,
            color: status === 200 ? "green" : "red",
            timeout: 2000,
        };
        commit("UPDATE_DOMAIN", data);
        dispatch("snackbar/setSnackbar", snack, {root: true});
        commit("SET_LOADING", false);
    },

    async syncDanAction({commit}) {
        commit("SET_DAN_SYNC", true);
        const {data} = await backendAPI.syncDanInventory();
        commit("UPDATE_DOMAINS", data);
        commit("SET_DAN_SYNC", false);
    },
    async syncCNMAction({commit}) {
        commit("SET_CNM_SYNC", true);
        const {data} = await backendAPI.syncCNMInventory();
        commit("UPDATE_DOMAINS", data);
        commit("SET_CNM_SYNC", false);
    },

    async loadDanMessagesByDomainNameAction({commit}, payload) {
        const {data} = await backendAPI.loadDanMessagesByDomainName(payload);
        if (data.length !== 0) {
            commit("ADD_ALL_MESSAGES", data);
        }
    },

    async loadInventoryDomainByNameAction({commit, dispatch}, name) {
        commit("SET_LOADING", true);
        const {data, status} = await backendAPI.loadInventoryDomainByName(name);
        const snack = {
            data: data,
            color: status === 200 ? "green" : "red",
            timeout: 2000,
        };
        commit("ADD_DOMAIN", data);
        // dispatch("snackbar/setSnackbar", snack, {root: true});
        commit("SET_LOADING", false);
    }
};

const getters = {
    getInventory: (state) => {
        return state.domains;
    },
    getDomainById: (state) => (id) => {
        let domain = state.domains.find((domain) => domain.id === id);
        return domain;
    },

    getDomainByName: (state) => (name) => {
        let domain = state.domains.find((domain) => domain.name.toLowerCase() === name.toLowerCase());
        return domain;
    },

    getDanMessagesByDomainName: (state) => (domainName) => {
        let result = state.danMessages.filter(
            (message) => message.domain.toLowerCase() === domainName.toLowerCase()
        );
        return result;
    },
};
const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced,
};
