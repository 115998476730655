<template>
  <v-card>
    <div v-if="whois === 'No Match'">
      No Match
    </div>
    <div v-else>
      <li
        v-for="line in whois.statuses"
        :key="line + Math.random()"
      >
        {{ line }}
      </li>
      <li
        v-for="line in whois.nameServers"
        :key="line + Math.random()"
      >
        {{ line }}
      </li>
      <div v-if="whois.expirationDate">
        Expires On:
        <date-display
          :date="whois.expirationDate"
          date-format="DD MMM YYYY"
        />
      </div>
      <div v-if="whois.creationDate">
        Created On:
        <date-display
          :date="whois.creationDate"
          date-format="DD MMM YYYY"
        />
      </div>
      <div v-if="whois.updateDate">
        Updated On:
        <date-display
          :date="whois.updateDate"
          date-format="DD MMM YYYY"
        />
      </div>
      <div v-if="whois.referral">
        Referral: {{ whois.referral }}
      </div>
      <div v-if="contacts">
        <div
          v-for="contact in contacts"
          :key="contact + Math.random()"
        >
          <div v-if="contact">
            {{ contact }}
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="whois.text">
          {{ whois.text }}
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {
    DateDisplay: () => import("./DateDisplay.vue"),
  },
  props: ["whois"],
  data() {
    return {
      contacts: [
        this.whois.registrant,
        this.whois.admin,
        this.whois.billing,
        this.whois.technical,
      ],
    };
  },
  created() {
    console.log(this.whois);
  },
};
</script>

<style></style>
