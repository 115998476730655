<template>
  <v-app>
    <navigation-drawer v-if="isAuthenticated"/>

    <app-bar v-if="isAuthenticated"/>
    <v-main>
      <router-view/>
    </v-main>
    <TheSnackbar/>
  </v-app>
</template>

<script>
import {mapMutations, mapActions} from "vuex";
import TheSnackbar from "@/components/core/Snackbar.vue";
import ws from "@/util/ws.client"

export default {
  components: {
    NavigationDrawer: () => import("./components/core/Drawer"),
    AppBar: () => import("./components/core/AppBar"),
    TheSnackbar,
  },
  props: {
    source: String,
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    ...mapActions("conversations", ["receiveMessageAction", "receiveSMSAction", "updateMessageStatusAction"]),

    ...mapActions("snackbar", ["setSnackbar"]),
    ...mapMutations("messages", [
      "ADD_MESSAGE",
    ]),
    showConversations() {
      this.$router.push("/conversations");
    },
    showProfile() {
      this.$router.push("/user");
    },
  },

  created() {
    this.$store.dispatch("templates/loadAllTemplates");
    ws.addHandler((data) => {
      // if (data.objectType === "MESSAGE") {
      console.log("Received MESSAGE : ", data);

      switch (data.eventType) {
        case "CREATE":
          //PLAY SOUND
          // this.setSnackbar({
          //   text: `${data.body.conversation.lead?data.body.conversation.domain:data.body.user.name}: ${data.body.text}`,
          //   link: `/conversation/${data.body.conversationId}`,
          //   color: "green",
          // });
          this.receiveMessageAction(data.body);
          break;
        case "UPDATE":
          //PLAY SOUND
          this.updateMessageStatusAction(data.body);
          // this.setSnackbar({
          //   text: `UPDATE: ${data.body.conversation.domain?data.body.conversation.domain:data.body.user.name}: ${data.body.text}`,
          //   link: `/conversation/${data.body.conversationId}`,
          //   color: "green",
          // });
          break;
        default:
          this.setSnackbar({
            text: `UNRECOGNIZED EVENT: ${data.body}`,
            color: "red",
          });
      }
      // } else if (data.objectType === "SMS") {
      //   console.log("Received SMS : ", data);
      //
      //   switch (data.eventType) {
      //     case "CREATE":
      //       this.receiveMessageAction(data.body);
      //       break;
      //
      //     default:
      //       this.setSnackbar({
      //         data: data.body,
      //         color: "red",
      //       });
      //   }
      //
      // } else {
      // console.log(data.objectType);
      // console.log(data.eventType);
      // console.log(JSON.stringify(data.body));
      // this.setSnackbar({
      //   text: `UNRECOGNIZED EVENT TYPE: ${data.objectType}`,
      //   color: "red",
      // });
      // }
    });
  },
};
</script>
