import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [

  // {
  //   path: "/dashboard",
  //   name: "dashboard",
  //   component: () => import("@/views/Dashboard.vue"),
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: "/scans",
  //   name: "scans",
  //   component: () => import("@/views/Scans.vue"),
  //   meta: { requiresAuth: true }
  // },

  {
    path: "/",
    name: "login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/inbox",
    name: "inbox",
    component: () => import("@/views/Conversations.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/messages",
    name: "messages",
    component: () => import("@/views/Messages.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/mail",
    name: "mail",
    component: () => import("@/views/Mail.vue"),
    meta: { requiresAuth: true }
  },

  {
    path: "/conversation/:id",
    name: "conversation",
    component: () => import("@/views/ConversationWindow.vue"),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/classification",
    name: "classification",
    component: () => import("@/views/Classification.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/templates",
    name: "template",
    component: () => import("@/views/Templates.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/backorders",
    name: "backorders",
    component: () => import("@/views/Backorders.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/bulktools",
    name: "bulktools",
    component: () => import("@/views/BulkTools.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/leads",
    name: "leads",
    component: () => import("@/views/Leads.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/domains",
    name: "domains",
    component: () => import("@/views/Domains.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/stats",
    name: "statistics",
    component: () => import("@/views/Statistics.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/inventory",
    name: "inventory",
    component: () => import("@/views/Inventory.vue"),
    meta: { requiresAuth: true }
  },
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

    // redirect to login page if user is not logged in and trying to access a restricted page
    const publicPages = ['/']
    const authRequired = !publicPages.includes(to.path)
    const loggedIn = localStorage.getItem('user')

    if (authRequired && !loggedIn) {
      // return next('/')
      router.push({ name: 'login', query: { redirect: to.path } });
    }

    next()
});

export default router;
