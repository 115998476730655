import Vue from "vue";
import Vuex from "vuex";
import backendAPI from "@/api/backend-api";
import templatesAPI from "@/api/templates-api";
import conversations from "./modules/conversations";
import templates from "./modules/templates";
import snackbar from "./modules/snackbar";
import domains from "./modules/domains";
import backorders from "./modules/backorders";
import apollo from "@/store/modules/apollo";
import leads from "./modules/leads";
import inventory from "./modules/inventory";
import messages from "./modules/messages"
import mail from "./modules/mail"
import conversationAPI from "@/api/conversation-api";
import messageAPI from "@/api/message-api";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        conversations,
        domains,
        templates,
        snackbar,
        leads,
        inventory,
        messages,
        mail,
        backorders,
        apollo
    },

    state: {
        drawer: null,
        domain_snapshots: [],
        user: null,
    },
    mutations: {

        SET_DRAWER(state, payload) {
            state.drawer = payload;
        },

        SET_USER_DOMAIN_SNAPSHOTS_DATA(state, userDomains) {
            console.log("Setting domain_snapshots:", userDomains.data);
            state.domain_snapshots = userDomains.data;
        },
        SET_USER_DATA(state, userData) {
            console.log("Setting state user to: ", userData);
            state.user = userData;
            localStorage.setItem("user", JSON.stringify(userData));
            backendAPI.setToken(userData.token);
            templatesAPI.setToken(userData.token);
            conversationAPI.setToken(userData.token);
            messageAPI.setToken(userData.token);
        },
        CLEAR_USER_DATA() {
            console.log("Clearing user state");
            localStorage.removeItem("user");
            backendAPI.setToken(null);
            templatesAPI.setToken(null);
            conversationAPI.setToken(null);
            messageAPI.setToken(null);
            window.sessionStorage.clear();
            location.reload();
        },
    },
    actions: {
        register({commit}, user) {
            return backendAPI.register(user).then((response) => {
                commit("SET_USER_DATA", response.data);
            });
        },
        login({commit}, credentials) {
            return backendAPI.login(credentials).then((response) => {
                commit("SET_USER_DATA", response.data);
            });
        },
        logout({commit}) {
            console.log("Logging Out", commit);
            commit("CLEAR_USER_DATA");
        },

    },
    getters: {
        isAuthenticated(state) {
            console.log("User is authenticated: ", !!state.user);
            return !!state.user;
        },
        getCurrentUser(state) {
            return state.user;
        },
        getSearchTerm(state) {
            return state.searchTerm;
        },
    },
});
