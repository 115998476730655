import axios from "axios";

const callAPI = axios.create({
  baseURL: ``,
  timeout: 1000,
});

const callLeadPath = "/call/lead";

export default {

  call(number){
    console.log("Calling to ", number);
    callAPI.post(`${callLeadPath}/${number}`, {timeout: 60000})
  },

  setToken(token) {
    console.log("setting auth header to", token);
    callAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
};
