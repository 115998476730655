import api from "@/api/backend-api";

const state = {
  domains: [],
  loading: false,
  active_date: new Date(),
  totalElements: 0,
};

const mutations = {
  ADD_DOMAIN(state, domain) {
    let updateIndex = state.domains.findIndex(
      (item) => item.name === domain.name
    );
    if (updateIndex == -1) {
      state.domains.push(domain);
    }else{
      state.domains[updateIndex]=domain;
    }
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  ADD_ALL(state, data) {
    state.domains = data;
  },

  SET_ACTIVE_DATE(state, payload){
    state.active_date=payload;
  },

};

const actions = {
  async loadByIdAction({ commit }, id) {
    commit("SET_LOADING", true);

    let response = await api.loadDomainById(id);
    console.log("Adding domain: ", response.data);
    commit("ADD_DOMAIN", response.data);
    commit("SET_LOADING", false);

  },
  async loadDomainByNameAction({ commit }, domainName) {
    let response = await api.loadDomainByName(domainName);
    commit("ADD_DOMAIN", response.data);
  },


  async search ({commit}, payload) {
    commit("SET_LOADING", true);
    let data = await api.searchDroppingDomains(payload);
    if(data.length!==0){
      commit("ADD_ALL", data);
    }
    commit("SET_LOADING", false);
  },

  async loadBackordersPageAction ({commit}, payload) {
    commit("SET_LOADING_BACKORDERS", true);
    let data = await api.searchBackorders(payload);
    if(data.content.length!==0){
      commit("SET_BACKORDERS", data.content);
    }
    commit("SET_LOADING_BACKORDERS", false);
  },


};

const getters = {
/*  getDomainById: (state) => (id) => {
    return state.domains.find((domain) => domain.id === id);
  },*/
  getDomainByName: (state) => (domName) => {
    return state.domains.find((domain) => domain.name && domain.name.toLowerCase() === domName.toLowerCase());
  },
  getDomainByNameFromAPI: () => (domName) => {
    return api.getDomainByName(domName);
  },
  getDomains: (state) => {
    return state.domains;
  },
  getBackordersText: (state) => {
    let text='';
    state.backorders.filter(b=>!b.deleted).forEach(b => text+=`${b.domainName.toLowerCase()}:15\n`)
    return text;
  },
};

const namespaced = true;

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
