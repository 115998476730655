<template>
  <v-row justify="center">
    <v-card>
      <v-card-title class="headline">
        Reminder for {{ snapshot.sibling }}
      </v-card-title>
      <v-datetime-picker
        v-model="datetime"
        label="Select Datetime"
      />
      <v-select
        v-model="reminder.action"
        :items="actions"
        @change="setReminder"
      />
      <v-textarea
        v-model="reminder.memo"
        color="teal"
      >
        <template #label>
          <div>Memo <small>(optional)</small></div>
        </template>
      </v-textarea>
      <v-card-text>
        Let Google help apps determine location. This means sending anonymous
        location data to Google, even when no apps are running.
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="green darken-1"
          text
        >
          Disagree
        </v-btn>

        <v-btn
          color="green darken-1"
          text
        >
          Agree
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
export default {
  props: ["snapshot"],
  data(){
      return{
          actions: [
              "call",
              "text"
          ]
      }
  }
};
</script>

<style></style>
