import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "@/api/backend-api";
import vuetify from './plugins/vuetify';
import moment from 'moment'
import axios from 'axios'


Vue.config.productionTip = false;
Vue.prototype.moment = moment;


new Vue({
  router,
  store,

  created() {
    axios.interceptors.response.use(
        response => response, // simply return the response
        error => {
          if (error.response.data.error === 'Unauthorized') { // if we catch a 401 error
            this.$store.dispatch('logout') // force a log out
          }
          return Promise.reject(error) // reject the Promise, with the error as the reason
        }
    )

    const userString = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("SET_USER_DATA", userData);
    }
    api.validate(store);
  },

  vuetify,
  render: h => h(App)
}).$mount("#app");
