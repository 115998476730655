const state ={
  snackbars: []
};
  
const mutations = {
  SET_SNACKBAR(state, snackbar) {
    state.snackbars = state.snackbars.concat(snackbar);
  },
};
  
const actions = {
  setSnackbar({commit}, payload) {
    let snack={
      showing: true,
      color: payload.color || 'success',
      data: payload.data,

    }
    commit('SET_SNACKBAR', snack);
  },
};

const namespaced = true;

export default {
  state,
  mutations,
  actions,
  namespaced,
};