<template>
  <div>
    <v-snackbar
      v-for="(snackbar, index) in snackbars.filter((s) => s.showing)"
      :key="snackbar.data + Math.random()"
      v-model="snackbar.showing"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      :style="`bottom: ${index * 60 + 8}px`"
    >
      <div v-if="snackbar.whois">
        <whois-dialog :whois="snackbar.text" />
      </div>
      <div v-else-if="snackbar.reminder">
        <reminder-dialog :snapshot="snackbar.snapshot" />
      </div>
      <div v-else>
        <div v-for="val in Object.keys(snackbar.data)" :key="val + Math.random()">{{val}}: {{snackbar.data[val]}}</div>
      </div>

      <v-btn
        v-if="snackbar.link"
        text
        @click="$router.push(snackbar.link)"
      >
        Open
      </v-btn>
      <v-btn
        text
        @click="snackbar.showing = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import WhoisDialog from "../WhoisDialog.vue";
import ReminderDialog from "../ReminderDialog.vue";

export default {
  components: { WhoisDialog, ReminderDialog },
  computed: {
    ...mapState("snackbar", ["snackbars"]),
  },
  methods:{
    pretty(json){
      let output="";
      for(let val in json){
        output+=val+": "+json[val]+"\n";
      }

      return output;
    }
  }

};
</script>

<style lang="scss" scoped></style>
