import axios from "axios";

const AXIOS = axios.create({
  baseURL: ``,
  timeout: 60000
});

const backorderPath = "/backorder";
const suggestionPath = "/suggest";
const leadPath = "/leads";
const apolloPath = "/apollo";



export default {
  async getApolloLead(id){
    return AXIOS.get(`${apolloPath}/lead/${id}`, {timeout: 600000});
  },

  async findApolloLeads(keyword){
    return AXIOS.get(`${apolloPath}/search/${keyword}`, {timeout: 600000});
  },

  async getSuggestion(payload){
    return AXIOS.get(`${suggestionPath}/conversation/`,  {timeout: 60000, params: payload});
  },

  async getMozURLData(domainName){
    return AXIOS.get(`/moz/url/${domainName}`, {timeout: 600000});
  },

  async evaluate(domainName){
    const data = AXIOS.get(`/evaluate/domainName/${domainName}`, {timeout: 60000});
    return data;
  },
  async loadDanMessagesByDomainName(domainName){
    const data = AXIOS.get(`/inventory/danMessages/${domainName}`, {timeout: 60000});
    return data;
  },
  async loadInventoryDomainByName(domainName){
    const data = AXIOS.get(`/inventory/${domainName}`, {timeout: 60000});
    return data;
  },
  async updateInventoryDomain(domain){
    const data = AXIOS.put(`/inventory/${domain.id}`, domain, {timeout: 10000});
    return data;
  },
  async syncDanInventory(){
    return AXIOS.get("inventory/syncDanInventory", {timeout: 60000});
  },
  async syncCNMInventory(){
    return AXIOS.get("inventory/syncCNMInventory", {timeout: 60000});
  },
  async sendMessageWithDan(message){
    const data = AXIOS.post("/inventory/danMessages", message, {timeout: 60000});
    return data;
  },
  async updateDanMessage(message){
    const data = AXIOS.put(`/inventory/danMessages/${message.id}`, message, {timeout: 60000});
    return data;
  },
  async loadAllLeads(){
    const data = AXIOS.get("/leads/all", {timeout: 60000});
    return data;
  },
  async searchLeads(payload){
    const response = await AXIOS.post("/leads/search", payload,  { timeout: 60000});
    return response;
  },
  async spamReports(payload){
    const response = await AXIOS.post("/leads/spamReports", payload,  { timeout: 60000});
    return response;
  },
  async bounces(payload){
    const response = await AXIOS.post("/leads/bounces", payload,  { timeout: 60000});
    return response;
  },
  async deleteLead(sibling){
    AXIOS.delete(`leads/${sibling}`);
  },
  async whois(whoisRequest){
    return AXIOS.post("/function/whois", whoisRequest, {timeout: 60000});
  },

  async backorder(order){
    return AXIOS.post(backorderPath, order);
  },

  async backorderByDate(date){
    return AXIOS.get(`${backorderPath}/date/${date}`);
  },

  async produceBackorders(date){
    return AXIOS.get(`${backorderPath}/produce/${date}`);
  },

  async validateEmail(email){
    return AXIOS.get(`/function/validateEmail/${email}`);
  },
  async validateMobile(number){
    return AXIOS.get(`/function/validateMobile/${number}`);
  },

  async deleteBackorder(id){
    return AXIOS.delete(`${backorderPath}/${id}`);
  },

  async updateBackorder(backorder){
    return AXIOS.put(`${backorderPath}/${backorder.id}`, backorder);
  },

  async updateLead(payload){
    return AXIOS.put(`${leadPath}/${payload.id}`, payload);
  },

  async updateLeadSending(payload){
    return AXIOS.put(`${leadPath}/updateSending/${payload.id}`, payload);
  },

  async suggestBet(payload){
    // return AXIOS.post("/backorder/suggest", payload, {headers: {"Content-Type": "text/plain"}});
    return AXIOS.post("/backorder/suggest", payload);

  },

  stage(){
    return AXIOS.get("/bunny/stage");
  },
  async getStats(){
    return AXIOS.get("/statistics/");
  },
  pushToScan() {
    return AXIOS.get(`/bunny/godaddymq`);
  },
  startPendingDeleteScan() {
    return AXIOS.get(`/bunny/pd`);
  },
  startAfterDeleteScan() {
    return AXIOS.get(`/bunny/hunt`);
  },
  startGoDaddyScan() {
    return AXIOS.get(`/bunny/gd`);
  },
  getUser(userId) {
    return AXIOS.get(`/user/` + userId);
  },
  loadDomainById(domainId) {
    return AXIOS.get(`/domain/${domainId}`, {timeout: 60000});
  },
  loadDomainByName(domainName) {
    return AXIOS.get(`/domain/name/${domainName}`, {timeout: 60000});
  },

  createUser(user) {
    return AXIOS.post("/user", user);
  },
  createOffer(offer) {
    return AXIOS.post("/offer", offer);
  },
  setToken(token) {
    AXIOS.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  register(user) {
    return AXIOS.post("/user/register", user, { timeout: 10000 });
  },
  login(user) {
    return AXIOS.post("/login", user, { timeout: 10000 });
  },
  getDomainByName(domainName) {
    return AXIOS.get("/domain/name/" + domainName, { timeout: 10000 });
  },
  scanPDDomain(domainName){
    return AXIOS.get("/domain/scan/" + domainName, { timeout: 600000 });

  },
  async searchDroppingDomains(payload){
    const response = await AXIOS.post("/domain/search",  payload,  { timeout: 60000});
    return response.data;
  },

  async searchBackorders(payload){
    const response = await AXIOS.post("/backorder/search", payload,  { timeout: 60000});
    return response;
  },
  async searchInventoryDomains(filter){
    const response = await AXIOS.post("/inventory/search", filter,  { timeout: 60000});
    return response.data;
  },
  async importInventory(payload){
    const response = await AXIOS.post("/inventory/list", payload, { timeout: 60000 });
    return response;

  },

  async importInventoryDomain(payload){
    const response = await AXIOS.post("/inventory/domain", payload, { timeout: 60000 });
    return response;

  },
  async loadLeadsByDomainName(domainName) {
    return AXIOS.get("/leads/domainName/" + domainName, { timeout: 10000 });
  },
  validate(store) {
    AXIOS.interceptors.response.use(
      (response) => response, // simply return the response
      (error) => {
        if (error.response && error.response.status === 401) {
          // if we catch a 401 error
          console.log("Not logged in!");
          store.dispatch("logout"); // force a log out
        }
        return Promise.reject(error); // reject the Promise, with the error as the reason
      }
    );
  },

};
