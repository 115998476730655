import axios from "axios";

const messageAPI = axios.create({
  baseURL: ``,
  timeout: 60000,
});

const path = "/message";
const messageSearchPath = path+"/search"
const messageSearchPathForDate = path+"/search/date"
const messagesLoadPath = path+"/page"
const rephrasedPath = path+"/rephrase"



export default {


  async send(message){
    return messageAPI.post(path, message, {timeout: 60000})
  },

  async update(message){
    return messageAPI.put(`${path}/${message.id}`, message, {timeout: 60000});
  },

  setToken(token) {
    console.log("setting auth header to", token);
    messageAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  async search(payload){
    return await messageAPI.post(messageSearchPath, payload, {timeout: 60000});
  },

  async searchForDate(payload){
    return await messageAPI.post(messageSearchPathForDate, payload, {timeout: 60000});
  },

  async rephrase(payload){
    console.log("Rephrasing", payload);
    return messageAPI.post(rephrasedPath, payload,  {timeout: 60000});
  },

  async load(){
    return await messageAPI.get(messagesLoadPath, {timeout: 60000});
  },
};
